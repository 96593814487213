<template>
  <d-right-menu
    @on:click-close="close"
  >
    <div class="w-100 d-flex" slot="header">
      <b-row class="align-content-center align-items-center w-100">
        <b-col align="left">
          <label class="header-label">
            {{ $t('planning_params.title') }}
          </label>
        </b-col>
        <b-col class="pr-0" align="right">
          <i class="icon-arrow-right fa-2x pointer" v-on:click="close()"></i>
        </b-col>
      </b-row>
    </div>
    <div slot="body">
      <b-row class="d-flex align-items-center">
        <b-col cols="6">
          <label class="font-avenir">{{ $t('planning_params.planning_mode') }}</label>
        </b-col>
        <b-col cols="6">
          <d-select-option
            v-model="params.orientation"
            :required="true"
            :options="[{text: 'Horizontal', value: 'horizontal'}, {text: 'Vertical', value: 'vertical'}]"
            :rules="null"
            :class-name="'background-light-blue-inputs'"
            @on:select-option:change="changeOr"
          />
        </b-col>
      </b-row>
      <b-row class="d-flex align-items-center">
        <b-col cols="12" class="title">
          <label class="font-avenir-label">{{ $t('planning_params.title_booking_type_form') }}</label>
        </b-col>
      </b-row>

      <template v-for="(color, i) in defaultColors">
        <b-row
          :key="'color' + i"
          class="d-flex align-items-center mt-1"
        >
          <b-col cols="6">
            <label class="font-avenir">
              {{ $t('booking_type.label.' + i) }}
            </label>
          </b-col>
          <b-col cols="6">
            <input
              type="color"
              :value="color"
              v-on:change="changeDefaultColor(i, $event)"
              class="background-light-blue-inputs form-control form-control-sm"
            />
          </b-col>
        </b-row>
      </template>
      <template v-for="(bookingCategory, i) of params.bookingCategories">
        <b-row
          class="mt-1 align-items-center"
          :key="'category' + i"
        >
          <b-col cols="6">
              <span
                class="mr-2 font-text-title text-danger"
                v-on:click="remove(bookingCategory.bookingCategory['@id'])"
              >
                <i class="fa pointer fa-minus"></i>
              </span>
            <label class="font-avenir">
              {{ bookingCategory.bookingCategory.name }}
            </label>
          </b-col>
          <b-col cols="6">
            <input
              type="color"
              :value="bookingCategory.color"
              class="background-light-blue-inputs form-control form-control-sm"
              v-on:change="changeColor(bookingCategory['@id'], $event)"
            />
          </b-col>
        </b-row>
      </template>

      <b-row class="d-flex align-items-center mt-3">
        <b-col
          v-if="addCategory"
          cols="9"
          align="center"
        >
          <d-text-field
            v-model="category"
            :required="true"
            :rules="null"
            :class-name="'background-light-blue-inputs'"
            size="sm"
          />
        </b-col>
        <b-col
          v-if="addCategory"
          class="mt-1"
          align="center"
          cols="3"
        >
          <i
            v-on:click="add()"
            class="pointer ml-2 mr-2 icons-size fa fa-check"
          />
          <i
            v-on:click="cancel()"
            class="pointer icons-size fa fa-close text"
          />
        </b-col>
        <b-col
          v-if="!addCategory"
          cols="12"
        >
          <add-link
            text-link="planning_params.add_new_booking_type"
            pointer="pointer"
            :class-icon="'fa fa-plus text-blue-darken'"
            @on:add-link-click="addCategory = true"
          />
        </b-col>
      </b-row>
    </div>
    <div class="w-100" slot="footer">
      <b-row class="d-flex align-items-center">
        <b-col cols="12">
              <span
                class="d-btn-sm font-text-title btn btn-block d-btn-primary font-text-title"
                v-on:click="close()"
              >
                {{ $t('planning_params.complete') }}
              </span>
        </b-col>
      </b-row>
    </div>
  </d-right-menu>
</template>
<script>
import {
  addBookingCategory, addColorPlanningParametersBookingCategory, changeOrientation,
  deleteCategory, getBookingCategories,
  getPlanningParameters, updColorPlanningParametersBookingCategory, updColorsPlanningParameters
} from "@api/doinsport/services/params-planning/params-planning.api";
import AddLink from "@custom/AddLink";
import {isNotUndefinedAndNotNull} from "@/utils/classes";
import {LESSON_TYPE} from "@/classes/doinsport/Price";

export default {
  props: {
    params: {
      type: Object,
      default: () => {
      },
    }
  },
  components: {AddLink},
  data: () => ({
    bookingCategories: [],
    defaultColors: {},
    addCategory: false,
    test: true,
    category: '',
  }),
  watch: {},
  computed: {},
  created() {
    this.load();
    this.loadDefaultColors();
  },
  methods: {
    load() {
      getBookingCategories()
        .then(res => {
          if (res.data) {
            this.bookingCategories = res.data['hydra:member'];
          }
        });
    },
    add() {
      const data = {
        name: this.category,
        club: '/clubs/' + this.$store.getters["auth/currentClub"].id
      };
      addBookingCategory(data)
        .then(res => {
          if (res) {
            const data = {
              bookingCategory: res.data['@id'],
              planningParameter: "/clubs/planning-parameters/" + this.params.id,
              color: '#000000'
            }
            addColorPlanningParametersBookingCategory(data)
              .then(res => {
                this.load();
                this.category = '';
                this.addCategory = false;
              })
              .finally(() => {
                this.$emit('on:params:update');
              })
            ;
          }
        });
    },
    cancel() {
      this.addCategory = false;
    },
    remove(iri) {
      deleteCategory(iri)
        .then(res => {
          this.load();
        })
        .finally(() => {
          this.$emit('on:params:update');
        })
      ;
    },
    changeDefaultColor(key, ev) {
      this.defaultColors[key] = ev.target.value;
      const data = {
        colors: this.defaultColors
      }
      updColorsPlanningParameters(
        "/clubs/planning-parameters/" + this.params.id,
        data
      ).then(res => {
        this.load();
      })
        .finally(() => {
          this.$emit('on:params:update');
        })
      ;
    },
    changeColor(iri, ev) {
      const data = {
        color: ev.target.value
      }
      updColorPlanningParametersBookingCategory(iri, data)
        .then(res => {
          this.load();
        })
        .finally(() => {
          this.$emit('on:params:update');
        })
      ;
    },
    changeOr() {
      changeOrientation(this.params.id, {orientation: this.params.orientation})
        .then(res => {
          this.load();
        })
        .finally(() => {
          this.$emit('on:params:update');
        })
      ;
    },
    close(e) {
      if (e === undefined || e.target.className === 'container-modal') {
        this.$emit('on:click-close');
      }
    },
    loadDefaultColors() {
      if (isNotUndefinedAndNotNull(this.params.colors)) {
        this.defaultColors = {
          booking_from_app: this.params.colors['booking_from_app'],
          booking_from_club: this.params.colors['booking_from_club'],
          booking_from_web: this.params.colors['booking_from_web'],
          recurring_booking: this.params.colors['recurring_booking'],
          lesson: this.params.colors['lesson'],
          formula: this.params.colors['formula'],
          match: this.params.colors['match'],
          playground_closure: this.params.colors['playground_closure'],
        };
      }
    }
  },
}
</script>
<style lang="scss" scoped>
/deep/ .custom-select {
  border: 1px solid #dddddd;
}

.icons-size {
  color: #b4c0c3;
  font-size: 18px;
}

.header-label {
  padding: 5px 10px;
  color: #707070;
  text-decoration: none;
  font: normal normal 900 18px Avenir;
}

/deep/ .label-text {
  font-size: 15px;
  padding-bottom: 0;
}

.font-avenir {
  font: normal normal 14px Avenir;
}

.font-avenir-label {
  font-family: Avenir;
}
</style>
