import { _get, _post, _delete,  _put, clubId } from "@api/doinsport/services/httpService";

const URI = "/clubs/planning-parameters";

export const getPlanningParameters = (paramId) => _get(URI + '/' + paramId);

export const updColorPlanningParametersBookingCategory = (iri, data) => _put(iri, data);

export const updColorsPlanningParameters = (iri, data) => _put(iri, data);

export const changeOrientation = (paramsId, data) => _put( URI + '/' + paramsId, data );

export const addBookingCategory = (data) => _post('/clubs/bookings/categories', data);

export const deleteCategory = (iri) => _delete(iri);

export const getBookingCategories = () => _get('/clubs/bookings/categories?club.id=' + clubId);

export const addColorPlanningParametersBookingCategory = (data) => _post(
    '/clubs/planning-parameters/booking-categories',
    data
);

export const putPlanningParameters = (iri, data) => _put(iri, data);
